<template>
<b-dropdown ref="game-select-dropdown" class="btn-select-dropdown w-100" variant="primary" no-flip toggle-class="text-decoration-none">
      <template v-slot:button-content>
            <span v-if="selected" class="d-inline-block text-truncate w-100 align-bottom" style="max-width:250px">
                  {{selected.gamenr}}.
                  <span v-if="hteam(selected)">{{hteam(selected).dispname}}</span>
                  <span v-else>TBD</span>
                  -
                  <span v-if="ateam(selected)">{{ateam(selected).dispname}}</span>
                  <span v-else>TBD</span>
            </span>
            <span v-else>
                  Alla matcher
            </span>
      </template>
      <div v-if="!loading" class="btn-select">
            <b-row v-if="showclear && id" class="align-items-center clear-select-row" @click="SelectGame(false)">
                  <b-col class="text-center p-3 bg-danger text-white">
                        Rensa val
                  </b-col>
            </b-row>
            <b-row @click="SelectGame(game)" v-for="game in games" :key="game.id" class="align-items-center btn-select-row" :class="{ 'btn-select-selected': isSelected(game)}">
                  <b-col cols="auto">
                        {{game.gamenr}}.
                        <label v-if="hteam(game)">{{hteam(game).dispname}}</label>
                        <label v-else>TBD</label>
                        -
                        <label v-if="ateam(game)">{{ateam(game).dispname}}</label>
                        <label v-else>TBD</label>
                  </b-col>
            </b-row>
      </div>
      <b-spinner variant="primary" v-else></b-spinner>
</b-dropdown>
</template>

<script>
export default {
      name: "GameSelect",
      props: {
            "id": String,
            showclear: Boolean
      },
      data() {
            return {};
      },
      computed: {
            loading() {
                  return this.$store.getters["tipsg/gamesloading"];
            },
            games() {
                  var data = this.$store.getters["tipsg/games"];
                  if (data) {
                        return data.games;
                  }
                  return [];
            },
            selected() {
                  var g = false;
                  if (this.id) {
                        for (var i = 0; i < this.games.length; i++) {
                              if (this.games[i].id == this.id)
                                    return this.games[i];
                        }
                  }
                  return g;
            }
      },
      methods: {
            isSelected(game){
                  if(this.selected && this.selected.id == game.id)
                        return true;
                  return false;
            },
            SelectGame(game) {
                  this.$emit("select", game);
                  this.$refs["game-select-dropdown"].hide();
            },
            flag(tid) {
                  var t = this._team(tid);
                  return this.$images.teamFlag(t.flag);
            },
            hteam(g) {
                  if (g.hometeamid > 0) {
                        return this._team(g.hometeamid);
                  }
                  return false;
            },
            ateam(g) {
                  if (g.awayteamid > 0) {
                        return this._team(g.awayteamid);
                  }
                  return false;
            },
            _team(id) {
                  return this.$functions.getTeam(id);
            }
      }
};
</script>
